import { Fab } from '@mui/material';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import ContactMessageModal from './ContactMessageModal/ContactMessageModal';

const ContactUsButton = () => {
  const [showModal, setShowModal] = useState(false);
  const { currentProject } = useSelector((state) => state.projects);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <Fab
        variant="extended"
        size="small"
        color="primary"
        style={{ right: 40, bottom: 40, position: 'fixed' }}
        onClick={() => handleShow()}
      >
        Contact Us
      </Fab>
      <ContactMessageModal
        showModal={showModal}
        handleClose={handleClose}
        projectId={currentProject?.projectId}
      />
    </>
  );
};

export default ContactUsButton;
