import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from 'cfa-react-components';
import { useState } from 'react';
import { useFormik } from 'formik';
import styles from './ContactMessageModal.module.scss';
import toastPromise from '../../../helpers/toast-promise';
import { usePostContactUsMutation } from '../../../store/projectsApi';

const maxLength = 500;

const pendingHandler = () => 'Sending contact request...';

const successHandler = () =>
  'An email has been sent with your information to the Vendor Bridge Support email address.';

const requestAndNotify = toastPromise(pendingHandler, successHandler);

const initialValues = {
  message: ''
};

const ContactMessageModal = ({ showModal, handleClose, projectId }) => {
  const [postContactUsMutation] = usePostContactUsMutation();
  const [messageLength, setMessageLength] = useState(0);
  const currentProjectId = projectId;

  const onFormikCancel = async () => {
    formik.values.message = '';
    handleClose();
  };

  const onFormikChange = async (event) => {
    setMessageLength(event.target.value.length);
    formik.handleChange(event);
  };

  const onFormikSubmit = async (values, { resetForm }) => {
    let response;
    const contactRequest = postContactUsMutation({
      currentProjectId: currentProjectId,
      values: {
        text: values.message
      }
    });

    try {
      response = await requestAndNotify(contactRequest.unwrap());
    } catch (error) {}

    if (response?.status === 200 || response === '') {
      handleClose();
      resetForm();
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onFormikSubmit,
    onChange: onFormikChange
  });

  return (
    <div className={styles['page-centered']}>
      <Dialog open={showModal} onClose={handleClose}>
        <DialogTitle>Contact Us</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <DialogContentText>
              Please enter a message for Vendor Bridge Support
            </DialogContentText>
            <div className={styles['centered-container']}>
              <textarea
                id="message"
                cols={60}
                rows={10}
                maxLength={maxLength}
                label="Message"
                value={formik.values.message}
                onChange={onFormikChange}
              />
            </div>
            <p>
              {messageLength}/{maxLength} characters
            </p>
            <p>{formik.touched.message && formik.errors.message}</p>
            <div className={styles['centered-container']}>
              <div>
                <Button
                  type="button"
                  size="small"
                  color="primary"
                  onClick={onFormikCancel}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  type="submit"
                  disabled={formik.values.message.length === 0}
                  color="secondary"
                  size="small"
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ContactMessageModal;
