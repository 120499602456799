import 'cfa-react-components/dist/styles.min.css';
import { Route, useHistory, Switch, Redirect } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import Profile from './pages/Profile/Profile';
import Login from './pages/Login/Login';
import Oauth from './pages/Oauth/Oauth';
import Development from './pages/Development/Development';
import Production from './pages/Production/Production';
import Overview from './pages/Overview/Overview';
import Documentation from './pages/Documentation/Documentation';
import Registration from './pages/Registration/Registration';
import { oktaAuthConfig, oktaSignInConfig } from './config/oktaConfig';
import './App.scss';
import NonProduction from './pages/NonProduction/NonProduction';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Certification from './pages/Certification/Certification';
import Admin from './pages/Admin/Admin';
import PendingCertifications from './pages/PendingCertifications/PendingCertifications';
import Swagger from './pages/Swagger/Swagger';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ApiCatalog from './pages/ApiCatalog/ApiCatalog';
import ProjectAdmin from './pages/ProjectAdmin/ProjectAdmin';
import { useEffect, useState } from 'react';
import { useGetProjectsQuery } from './store/projectsApi';
import { useGetCertificationsQuery } from './store/certificationsApi';
import { useGetOnboardingUserQuery } from './store/usersApi';
import { useDispatch } from 'react-redux';
import { useGetApisQuery } from './store/apisApi';
import { authActions } from './store/auth';
import ManageScopes from './pages/ManageScopes/ManageScopes';
import ListInvites from './pages/ListInvites/ListInvites';
import ContactUsButton from './components/Widget/ContactUsButton';

const theme = createTheme({
  typography: {
    fontFamily: `"Apercu", "Roboto", "Helvetica", "Arial", sans-serif`
  }
});

const oktaAuth = new OktaAuth(oktaAuthConfig);

const App = () => {
  const [oktaUser, setOktaUser] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const getOktaUser = async () => {
      try {
        const { headers, ...oktaUserResponse } = await oktaAuth.getUser();
        let accessToken = oktaAuth.getAccessToken();
        if (JSON.stringify(oktaUserResponse) !== JSON.stringify(oktaUser)) {
          setOktaUser(oktaUserResponse);
          dispatch(
            authActions.setAuthState({
              accessToken: accessToken,
              user: oktaUserResponse,
              isAdmin: oktaUserResponse?.groups.includes(
                'VENDOR_ONBOARDING-ADMIN'
              )
            })
          );
        }
      } catch (error) {}
    };

    getOktaUser();
  }, [oktaUser, dispatch]);

  useGetProjectsQuery();
  useGetCertificationsQuery();
  useGetOnboardingUserQuery(oktaUser.email);
  useGetApisQuery();

  const history = useHistory();

  const customAuthHandler = () => {
    history.push('/login');
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <div className={'app'}>
      <ThemeProvider theme={theme}>
        <Security
          oktaAuth={oktaAuth}
          onAuthRequired={customAuthHandler}
          restoreOriginalUri={restoreOriginalUri}
        >
          <Switch>
            <SecureRoute
              path={['/', '/home']}
              exact={true}
              component={Overview}
            />
            <SecureRoute path="/profile" component={Profile} />
            <SecureRoute path="/overview" component={Overview} />
            <SecureRoute path="/admin" component={Admin} />
            <SecureRoute path="/pending" component={PendingCertifications} />
            <SecureRoute path="/manage-scopes" component={ManageScopes} />
            <SecureRoute path="/allinvites" component={ListInvites} />
            <Route
              exact
              path="/login"
              render={() => <Login config={oktaSignInConfig} />}
            />
            <Route path="/login/callback" component={LoginCallback} />
            <SecureRoute path="/oauth" component={Oauth} />
            <SecureRoute path="/nonprod" component={NonProduction} />
            <SecureRoute path="/development" component={Development} />
            <SecureRoute path="/production" component={Production} />
            <Route path="/register" component={Registration} />
            <SecureRoute path="/certifications" component={Certification} />
            <SecureRoute path="/swagger-ui/:id" component={Swagger} />
            <SecureRoute path="/api-catalog" component={ApiCatalog} />
            <SecureRoute path="/project-admin" component={ProjectAdmin} />
            <SecureRoute path="/documentation/:id" component={Documentation} />
            <Redirect to="/" />
          </Switch>
        </Security>
      </ThemeProvider>
      <ContactUsButton />
      <ToastContainer autoClose={7000} style={{ fontSize: '0.4em' }} />
    </div>
  );
};
export default App;
