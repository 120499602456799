import {
  Logo,
  Icon,
  NavLink,
  SideNavigationWrapper,
  SideNavigation,
  SideNavigationContent
} from 'cfa-react-components';
import { useSelector } from 'react-redux';
import { NavLink as ReactRouterNavLink, useHistory } from 'react-router-dom';
import ProjectSelector from './ProjectSelector';
import { Button, Typography } from '@mui/material';
import useAuthentication from '../../hooks/use-authentication';
import Avatar from '@mui/material/Avatar';
import { useGetOnboardingUserQuery } from '../../store/usersApi';
import './ProjectSideNav.css';

const disabledNavLinkStyling = { pointerEvents: 'none', opacity: '0.4' };

const ProjectSideNav = () => {
  const history = useHistory();
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const { user, logOut } = useAuthentication();
  const { currentProject } = useSelector((state) => state.projects);
  const { data: onboardingUser } = useGetOnboardingUserQuery(user?.email);

  const vendorStatus =
    currentProject?.vendor?.status || onboardingUser?.vendor?.status;
  const projectLinksVisible = isAdmin || vendorStatus === 'ACTIVE';

  const userIsProjectAdmin =
    onboardingUser &&
    currentProject &&
    onboardingUser.email.toLowerCase() ===
      currentProject.primaryContactEmail.toLowerCase();

  const links = {
    overview: {
      text: 'Home',
      link: '/overview',
      active: true,
      visible: true,
      icon: 'info-outline'
    },
    apis: {
      text: 'Api Catalog',
      link: '/api-catalog',
      active: true,
      visible: true,
      icon: 'menu'
    },
    certifications: {
      text: 'Certifications',
      link: '/certifications',
      active: false,
      visible: true,
      icon: 'create'
    },
    oauth: {
      text: 'OAuth in Action',
      link: '/oauth',
      active: true,
      visible: projectLinksVisible,
      icon: 'chevron-down'
    },
    development: {
      text: 'Development',
      link: '/development',
      active: false,
      visible: projectLinksVisible,
      icon: 'chevron-down'
    },
    nonprod: {
      text: 'Non-Production',
      link: '/nonprod',
      active: false,
      visible: projectLinksVisible,
      icon: 'chevron-down'
    },
    production: {
      text: 'Production',
      link: '/production',
      active: false,
      visible: projectLinksVisible,
      icon: 'chevron-down'
    }
  };

  const linkPropNames = [
    'overview',
    'apis',
    'certifications',
    'oauth',
    'development',
    'nonprod',
    'production'
  ];

  function handleProjectStage() {
    if (currentProject) {
      switch (currentProject['projectStatus']) {
        case 'STEP_1':
          break;
        case 'STEP_2':
          links['development'].active = true;
          break;
        case 'STEP_3':
          links['nonprod'].active = true;
          links['development'].active = true;
          break;
        case 'STEP_4':
        case 'LIVE':
          links['nonprod'].active = true;
          links['development'].active = true;
          links['production'].active = true;
          break;
        default:
          break;
      }
    }
  }

  function updateCertificationsLink() {
    if (isAdmin && !currentProject) {
      links.certifications.link = '/pending';
      links.certifications.active = true;
    } else {
      links.certifications.link = '/certifications';
      links.certifications.active = true;
    }
  }

  const handleSignOut = async () => {
    try {
      logOut();
      history.push('/login');
    } catch (err) {}
  };

  handleProjectStage();
  updateCertificationsLink();

  return (
    <div className="side-nav-content">
      <SideNavigationWrapper>
        <SideNavigation className={'side-nav-frame'}>
          <SideNavigationContent
            section="start"
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '16px'
            }}
          >
            <Logo logo="symbolWithDots" size="lg" color="primary" />
          </SideNavigationContent>
          <SideNavigationContent
            section="middle"
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {linkPropNames
              .filter((stage) => links[stage].visible === true)
              .map((stage, index) => (
                <NavLink
                  key={links[stage].text}
                  to={links[stage].link}
                  variant="sidenavigation-link"
                  as={ReactRouterNavLink}
                  icon={<Icon icon={links[stage].icon} />}
                  style={links[stage].active ? {} : disabledNavLinkStyling}
                >
                  {links[stage].text}
                </NavLink>
              ))}
          </SideNavigationContent>
          <SideNavigationContent section="end">
            <ProjectSelector></ProjectSelector>
            {(isAdmin || userIsProjectAdmin) && (
              <NavLink
                key={'Project Admin'}
                to={'/project-admin'}
                variant="sidenavigation-link"
                as={ReactRouterNavLink}
                icon={<Icon icon={'wrench'} />}
              >
                {'Project Admin'}
              </NavLink>
            )}
            {isAdmin && (
              <NavLink
                key={'Admin'}
                to={'/admin'}
                variant="sidenavigation-link"
                as={ReactRouterNavLink}
                icon={<Icon icon={'user'} />}
              >
                {'Admin'}
              </NavLink>
            )}
          </SideNavigationContent>
          <SideNavigationContent
            section="end"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {user && (
              <>
                <Avatar
                  style={{ marginBottom: 8, marginTop: 8 }}
                  name={user.name}
                />
                <Typography variant="caption1" style={{ fontSize: '16px' }}>
                  {user.name}
                </Typography>
                <Typography variant="caption2" color="disabled">
                  <Button onClick={handleSignOut}>
                    <Typography sx={{ textTransform: 'none' }}>
                      Sign Out
                    </Typography>
                  </Button>
                </Typography>
              </>
            )}
          </SideNavigationContent>
        </SideNavigation>
      </SideNavigationWrapper>
    </div>
  );
};

export default ProjectSideNav;
