import { useState } from 'react';
import { useSelector } from 'react-redux';
import Template from '../Template/Template';
import { getProjectInfo } from '../../helpers/project-data';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button, TextField } from 'cfa-react-components';
import styles from '../Production/Production.module.scss';
import toastPromise from '../../helpers/toast-promise';
import CFASpinner from '../../components/Widget/CFASpinner/CFASpinner';
import ShowSecretConfirmation from '../../components/Widget/ShowSecretConfirmation';
import {
  useUpdateProjectMutation,
  useActivateProjectMutation,
  useGetProjectDataQuery,
  usePatchProjectDataMutation
} from '../../store/projectsApi';

const Production = () => {
  const textFieldErrorStyling = styles['cfa-text-field-error'];
  const { currentProject } = useSelector((state) => state.projects);
  const { environmentData, scopesList, scopes } = getProjectInfo(
    currentProject,
    'PRODUCTION'
  );

  const [activateProject] = useActivateProjectMutation();

  const [updateProject] = useUpdateProjectMutation();

  const [patchProjectData] = usePatchProjectDataMutation();

  const productionUrlSet = new Set(
    scopes?.map((scope) => scope?.api?.productionUrl).filter((url) => !!url)
  );

  const productionUrls = Array.from(productionUrlSet?.values())?.join(', ');

  const pendingHandler = () => 'Submitting...';

  const successHandler = () =>
    'You will receive an email once the credentials have been created.';

  const successShowHandler = () => 'Secret displayed';

  const pendingHandlerActivate = () => 'Activating...';

  const successHandlerActivate = () => 'Vendor activated in Vendor Bridge.';

  const requestAndNotifyShow = toastPromise(pendingHandler, successShowHandler);
  const requestAndNotify = toastPromise(pendingHandler, successHandler);
  const requestAndNotifyActivate = toastPromise(
    pendingHandlerActivate,
    successHandlerActivate
  );

  const continueToActive = async () => {
    try {
      await requestAndNotifyActivate(
        activateProject({
          currentProjectId: currentProject.projectId
        })
      );
    } catch (error) {}
  };

  const isLoading = !currentProject;

  const projectDataPayload = {
    projectId: currentProject?.projectId,
    projectDataId: currentProject?.data.find(
      (pd) => pd.environment === 'PRODUCTION'
    )?.projectDataId
  };
  var { data: projectData } = useGetProjectDataQuery(projectDataPayload);

  const isAdmin = useSelector((state) => state.auth.isAdmin);

  const invalidProjectStatus = !(
    currentProject?.projectStatus &&
    currentProject?.projectStatus !== 'STEP_1' &&
    currentProject?.projectStatus !== 'STEP_2' &&
    currentProject?.projectStatus !== 'STEP_3'
  );

  const formik = useFormik({
    initialValues: {
      redirectUrl: '',
      landingPage: '',
      supportEmail: '',
      environment: 'PRODUCTION'
    },
    validationSchema: yup.object({
      redirectUrl: yup.string().url('Invalid URL').required('Required'),
      landingPage: yup.string().url('Invalid URL').required('Required'),
      supportEmail: yup
        .string()
        .email('Invalid email address')
        .required('Required')
    }),
    onSubmit: async (values, { setSubmitting, isSubmitting }) => {
      setSubmitting(true);

      await requestAndNotify(
        updateProject({
          currentProjectId: currentProject.projectId,
          values: values
        }).unwrap()
      );

      setSubmitting(false);
    }
  });

  const patchProjectDataFromShow = async () => {
    try {
      await requestAndNotifyShow(
        patchProjectData({
          currentProjectId: projectData?.project?.projectId,
          projectDataId: projectData?.projectDataId,
          values: {
            showSecret: projectData.showSecret
          }
        }).unwrap()
      );
    } catch (error) {}
  };

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleConfirm = () => {
    setShowModal(false);
    displaySecret();
  };

  const displaySecret = () => {
    try {
      if (projectData) {
        projectData = {
          ...projectData,
          showSecret: false
        };
        patchProjectDataFromShow();
        document.getElementById('secret').innerHTML = projectData.secret;
        document.getElementById('secretButton').remove();
      }
    } catch (error) {}
  };

  const form = (
    <>
      <div>
        <div className={styles['prod-body']}>
          <p>
            Congratulations, you are about ready to deploy your app to
            production!
          </p>
          <p>
            Please provide the following information and we’ll submit a request
            for your production Okta credentials. This process could take up to
            two weeks but please check email regularly for notification the
            credentials have been created. After receiving your credentials, you
            will be able to move forward with your production deployment.
          </p>
        </div>
      </div>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles['prod-form-fields']}>
            <TextField
              required
              fullWidth
              id="redirectUrl"
              name="redirectUrl"
              label="Prod Okta Redirect URL"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.redirectUrl}
            />
            {formik.errors.redirectUrl && formik.touched.redirectUrl ? (
              <div className={textFieldErrorStyling}>
                {formik.errors.redirectUrl}
              </div>
            ) : null}

            <TextField
              required
              fullWidth
              id="landingPage"
              name="landingPage"
              label="Prod Login Form URL (where the Operator will redirect to after enrolling in Vendor Bridge)"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.landingPage}
            />
            {formik.errors.landingPage && formik.touched.landingPage ? (
              <div className={textFieldErrorStyling}>
                {formik.errors.landingPage}
              </div>
            ) : null}

            <TextField
              required
              fullWidth
              id="supportEmail"
              name="supportEmail"
              label="Vendor Support Email Address"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.supportEmail}
            />
            {formik.errors.supportEmail && formik.touched.supportEmail ? (
              <div className={textFieldErrorStyling}>
                {formik.errors.supportEmail}
              </div>
            ) : null}
          </div>

          <Button
            className={styles['prod-form-button']}
            disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}
            color="primary"
            type="submit"
          >
            Submit
          </Button>
        </form>
      </div>
    </>
  );

  const prodInfo = (
    <>
      <pre>
        <code style={{ display: 'block' }}>
          OKTA LOGIN URL:
          https://custom-okta-login.vendor-bridge.api.cfahome.com/login <br />
          TOKEN URL:
          https://cfahome.okta.com/oauth2/aus8ocete5lxHSSuq1t7/v1/token <br />
          CLIENT ID: {environmentData?.clientId} <br />
          SECRET:
          <span id="secret" className={styles.highlight}>
            {(currentProject?.projectStatus !== 'LIVE' &&
              environmentData?.secret) ||
              `**********`}{' '}
          </span>
          {projectData?.showSecret && !isAdmin ? (
            <Button id="secretButton" color="primary" onClick={handleShow}>
              Show Secret
            </Button>
          ) : (
            ''
          )}
          <br />
          API URL(S): {productionUrls || []} <br />
          SCOPES: offline_access {scopesList || []} <br />
          REDIRECT URL: {environmentData?.redirectUrl} <br />
        </code>
      </pre>
    </>
  );

  let content;

  if (isLoading) {
    content = <CFASpinner />;
  } else if (invalidProjectStatus && !isAdmin) {
    content = <p>Access is denied to this page.</p>;
  } else if (currentProject && currentProject?.projectStatus === 'LIVE') {
    content = (
      <div>
        <div className={styles['prod-header']}>4. Production</div>
        <p>
          You are currently active in Vendor Bridge. Please note the following
          settings that will be needed for your production deployment:
        </p>
        {prodInfo}
        <ShowSecretConfirmation
          showModal={showModal}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
        />
      </div>
    );
  } else {
    content = (
      <div>
        <div className={styles['prod-header']}>4. Production</div>
        {!environmentData && form}
        {environmentData &&
          (!environmentData.secret || !environmentData.clientId) && (
            <div>
              <p>
                Your prod credentials request has been submitted. Please be
                patient as the process can take up to two weeks. You will be
                notified via email when they’re ready.
              </p>
            </div>
          )}
        {environmentData &&
          environmentData.secret &&
          environmentData.clientId && (
            <div>
              <p>Your production credentials are ready!</p>
              <p>
                You can now deploy your applications to your production
                environment. Please note the following settings that will be
                needed for your production deployment:
              </p>

              {prodInfo}

              <p>
                When you are deployed to production and ready to be enabled in
                Vendor Bridge, please click the{' '}
                <span className={styles.highlight}>
                  Activate me in Vendor Bridge
                </span>{' '}
                button below.
              </p>
              <Button color="primary" onClick={continueToActive}>
                Activate Me in Vendor Bridge
              </Button>
            </div>
          )}
      </div>
    );
  }

  return (
    <Template>
      {<div className={styles['prod-content']}>{content}</div>}
    </Template>
  );
};

export default Production;
