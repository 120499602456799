import { onboardingApi } from './onboardingApi';
import { getStoredSelectedProject } from '../service/LocalStorageService';
import { projectsActions } from './projects';

const getCurrentProject = (projects) => {
  const storedSelectedProject = getStoredSelectedProject(projects);
  if (storedSelectedProject !== undefined && storedSelectedProject !== null) {
    return storedSelectedProject;
  }
  const currentProject =
    projects !== null && projects.length >= 1 ? projects[0] : null;
  return currentProject;
};

const projectsApi = onboardingApi.injectEndpoints({
  endpoints: (build) => ({
    getProjects: build.query({
      query: () => {
        return {
          url: `projects`,
          method: 'get'
        };
      },
      keepUnusedDataFor: 300,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const currentProject = getCurrentProject(data);
          dispatch(projectsActions.setCurrentProject(currentProject));
        } catch (err) {}
      },
      providesTags: ['projects']
    }),
    getProject: build.query({
      query: (projectId) => {
        return {
          url: `projects/${projectId}`,
          method: 'get'
        };
      },
      providesTags: ['projects']
    }),
    patchProject: build.mutation({
      query: (data) => {
        const { currentProjectId, values } = data;
        return {
          url: `projects/${currentProjectId}`,
          method: 'patch',
          data: { ...values }
        };
      },
      invalidatesTags: ['projects']
    }),
    updateProject: build.mutation({
      query: (data) => {
        const { currentProjectId, values } = data;
        return {
          url: `projects/${currentProjectId}`,
          method: 'put',
          data: { ...values }
        };
      },
      invalidatesTags: ['projects']
    }),
    updateProjectRedirectUrl: build.mutation({
      query: (data) => {
        const { currentProjectId, values } = data;
        return {
          url: `projects/${currentProjectId}/okta-redirect-url`,
          method: 'patch',
          data: { ...values }
        };
      },
      invalidatesTags: ['projects']
    }),
    activateProject: build.mutation({
      query: (data) => {
        const { currentProjectId } = data;
        return {
          url: `projects/${currentProjectId}/activate`,
          method: 'post'
        };
      },
      invalidatesTags: ['projects']
    }),
    createNewProject: build.mutation({
      query: (data) => {
        return {
          url: `projects`,
          method: 'post',
          data: { ...data }
        };
      },
      invalidatesTags: ['projects', 'certifications']
    }),
    updateProjectScopes: build.mutation({
      query: (data) => {
        return {
          url: `projects/${data.projectId}/scopes`,
          method: 'put',
          data: {
            scopeIds: data.scopeIds
          }
        };
      },
      invalidatesTags: ['projects']
    }),
    getProjectData: build.query({
      query: (data) => {
        const { projectId, projectDataId } = data;
        return {
          url: `projects/${projectId}/data/${projectDataId}`,
          method: 'get'
        };
      },
      providesTags: ['projects']
    }),
    patchProjectData: build.mutation({
      query: (data) => {
        const { currentProjectId, projectDataId, values } = data;
        return {
          url: `projects/${currentProjectId}/data/${projectDataId}`,
          method: 'patch',
          data: { ...values }
        };
      },
      providesTags: ['projects']
    }),
    postContactUs: build.mutation({
      query: (data) => {
        const { currentProjectId, values } = data;
        return {
          url: `projects/contact/${currentProjectId}`,
          method: 'post',
          data: { ...values }
        };
      }
    })
  }),
  overrideExisting: false
});

export const {
  useGetProjectsQuery,
  useGetProjectQuery,
  usePatchProjectMutation,
  useActivateProjectMutation,
  useCreateNewProjectMutation,
  useUpdateProjectMutation,
  useUpdateProjectRedirectUrlMutation,
  useUpdateProjectScopesMutation,
  useGetProjectDataQuery,
  usePatchProjectDataMutation,
  usePostContactUsMutation
} = projectsApi;
